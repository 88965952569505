import React, {Component} from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

class Jobs extends Component{
    render(){
        return(
            <React.Fragment>
                
            <ReactFullpage
                    scrollOverflow={true}
                    
                    scrollbar={true}
                    
                    render={({ state, fullpageApi }) => {
                    return ( 
                        <section id="fullpage2">
                        <section id='Projectsection' className="section wow slideInRight" data-wow-duration=".8s">
                            <div className="jobs-video">
                                <a href="#">
                                <div className="jobs-text-section">
                                    <h2>Lead UX Designer (Miami)</h2>
                                    <span className="job-locations">Miami</span>
                                </div>
                            </a>
                                <span className="mousebtn whitebtn">
                                    <span className="mousebtn-wheel white"></span>
                                </span>
                            </div>
                        </section>
                        <section id='project-mid-section' className="section project-mid-section">
                            <div className="job-mid-section">
                                <div className="jobs-row">
                                    <div className="job-left-col">
                                        <div className="job-left-col-content">
                                            <h2>
                                                Join our team
                                            </h2>
                                            <p>
                                                Our Chief Recruitment Officer owns the end to end process and success of headhunting and recruitment here at Fantasy. The role can be remote or in-house (New York or San Francisco) and is focused on growing our UX and Creative arms globally.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-right-col">
                                        <div className="job-right-col-content" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='project-mid-section' className="section project-mid-section">
                            <div className="job-mid-section">
                                <div className="jobs-row">
                                    <div className="job-left-col">
                                        <div className="job-left-col-content responsibility">
                                            <h2>
                                                Responsibilities
                                            </h2>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-right-col">
                                        <div className="job-right-col-content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='project-mid-section' className="section project-mid-section">
                            <div className="job-mid-section">
                                <div className="jobs-row">
                                    <div className="job-left-col">
                                        <div className="job-left-col-content responsibility">
                                            <h2>
                                                Qualifications
                                            </h2>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-right-col">
                                        <div className="job-right-col-content" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='project-mid-section' className="section project-mid-section">
                            <div className="job-mid-section">
                                <div className="jobs-row">
                                    <div className="job-left-col">
                                        <div className="job-left-col-content responsibility">
                                            <h2>
                                                Professional Experience
                                            </h2>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-right-col">
                                        <div className="job-right-col-content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='project-mid-section' className="section project-mid-section">
                            <div className="job-mid-section">
                                <div className="jobs-row">
                                    <div className="job-left-col">
                                        <div className="job-left-col-content">
                                            <h2>
                                                Join our team
                                            </h2>
                                            <p>
                                                We worked to turn complex human-data into stories that illuminated users’ ancestry and genetics.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-right-col">
                                        <div className="job-right-col-content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='project-mid-section' className="section project-mid-section">
                            <div className="job-mid-section">
                                <div className="jobs-row">
                                    <div className="job-left-col">
                                        <div className="job-left-col-content">
                                            <h2>
                                                Join our team
                                            </h2>
                                            <p>
                                                We worked to turn complex human-data into stories that illuminated users’ ancestry and genetics.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-right-col">
                                        <div className="job-right-col-content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='project-mid-section' className="section project-mid-section">
                            <div className="job-mid-section">
                                <div className="jobs-row">
                                    <div className="job-left-col">
                                        <div className="job-left-col-content responsibility">
                                            <h2>
                                                CheckList
                                            </h2>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                            <p>
                                                <span className="add"><img src="assets/img/add.png"/></span>Headhunting for our New York, San Francisco, Miami offices as well as our growing remote teams in Russia and Asia Pacific.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-right-col">
                                        <div className="job-right-col-content" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='project-mid-section' className="section project-mid-section">
                            <div className="job-mid-section">
                                <div className="jobs-row">
                                    <div className="job-left-col">
                                        <div className="job-left-col-content">
                                            <h2>
                                                Join our team
                                            </h2>
                                            <p>
                                                We worked to turn complex human-data into stories that illuminated users’ ancestry and genetics.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-right-col">
                                        <div className="job-right-col-content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='projectfootersection' className="section projectfootersection">
                                <div className="project-footer">
                                    <h2>Excited enough?</h2>
                                    <a href="">
                                        <span className="intro-btn">Apply</span>
                                    </a>
                                </div>
                        </section>
                        <section id='submits' className="section submits">
                                <div className="submit-footer">
                                    <form>
                                        <div className="form-item-row">
                                            <div className="form-col-6">
                                                <div className="form-col">
                                                    <input type="text" name="text" placeholder="Full Name"/>
                                                </div>
                                            </div>
                                            <div className="form-col-6">
                                                <div className="form-col">
                                                    <input type="text" name="text" placeholder="Email"/>
                                                </div>
                                            </div>
                                            <div className="form-col-6">
                                                <div className="form-col">
                                                    <input type="text" name="text" placeholder="Phone"/>
                                                </div>
                                            </div>
                                            <div className="form-col-6">
                                                <div className="form-col">
                                                    <input type="text" name="text" placeholder="Location"/>
                                                </div>
                                            </div>
                                            <div className="form-col-6">
                                                <div className="form-col">
                                                    <input type="text" name="text" placeholder="Linkedin"/>
                                                </div>
                                            </div>
                                            <div className="form-col-6">
                                                <div className="form-col">
                                                    <input type="text" name="text" placeholder="Dribble"/>
                                                </div>
                                            </div>
                                            <input className="sub-btn" type="button" name="Submit" value="Submit"/>
                                        </div>
                                    </form>
                                </div>
                        </section>
                        </section>
                        
                        
                        );
                    }}
                />
        )
        
        </React.Fragment>
        )
    }
}

export default Jobs;
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Route,Link, BrowserRouter as Router} from 'react-router-dom';
import App from '../App';
import About from './Pages/About';
import Career from './Pages/Career';
import Jobs from './Pages/Jobs';
import Home from './Pages/Home';
import Works from './Pages/Works';


const myRouter = (
	<Router>
	<div className="sidebarnav-div">
					<a href="#"><img src="http://cdn.microwebs.co/assets/img/32.png" className="img-logo-nav"/></a>
		<ul className="navtop">
			<Link to="/Works">PortFolio</Link>
			<Link to="/About">About</Link>
			<Link to="/Career">Career</Link>
			<Link to="/Jobs">Jobs</Link>

		</ul>
		<ul className="navmid">
			<Link to="/News">News</Link>
			<Link to="/Career">Career</Link>
			<Link to="/Jobs">Resources</Link>
		</ul>
		<Route exact path="/" component={App} />
		<Route path="/Works" component={Works} />
		<Route path="/About" component={About} />
		<Route path="/Career" component={Career} />
		<Route path="/Jobs" component={Jobs} />
	</div>

	</Router>

);

class Header  extends Component{
render(){
    return(
        <React.Fragment>
            <div class="body_overlay"></div>
<header className="fixedNav" id="fixedNav">
	<div className="navWrapper">
		<div className="navlogo-left">
			<a href="/" className="navLogo">
				<img src="http://cdn.microwebs.co/assets/img/32.png" className="img-logo" alt="Logo"/>
			</a>
		</div>
		<nav className="canvasNav" id="canvasNav">
			<div class="menu-icon">
				<span></span>
				<span></span>
				<span></span>
			</div>

			<div className="sidebarnav">
				<div className="sidebarnav-div">
					<a href="#"><img src="http://cdn.microwebs.co/assets/img/32.png" className="img-logo-nav"/></a>
					<ul className="navtop">
					    <li><a href="work.html">Product</a></li>
						<li><a href="work.html">Work</a></li>
						<li><a href="about.html">About</a></li>
						<li><a href="contact.html">Contact</a></li>
					</ul>
					<ul className="navmid">
						<li><a href="#">News</a></li>
						<li><a href="career.html">Career</a></li>
						<li><a href="#">Resources</a></li>
						<li><a href="#">Twitter</a></li>
						<li><a href="#">Linkedin</a></li>
					</ul>
					<div className="nav-contact">
						<div className="nav-contact-item">
							<h5>Dhaka</h5>
							<p>
								Bangladesh
							</p>
						</div>
						
					</div>
				</div>
			</div>
		</nav>
		
	</div>
</header>
        </React.Fragment>
    )
}
}
export default  Header;
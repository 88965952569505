import React , { Component } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

class About extends Component{
    render(){
        return(
            <React.Fragment>
                <ReactFullpage
                    scrollOverflow={true}
                    
                    scrollbar={true}
                    
                    render={({ state, fullpageApi }) => {
                    return (
                    <div id="fullpage">

<section id='aboutSectionTop' className="section">
	<div className="intro-video">
		<video className="video-intro" autoplay="autoplay"  preload="auto">
			<source src="https://cdn.microwebs.co/assets/videos/" type="video/mp4"/>
		</video>
		<div className="introsection-content aboutintro">
				<h2>A human centered<br/>design agency<br/> that <span className="color">pioneers</span></h2>
		</div>
		<span className="mousebtn">
			<span className="mousebtn-wheel"></span>
		</span>
	</div>
</section>
<section id='aboutSectionPower' className="section">
	<div class="intro-video future-video">
		<video className="video-intro video-future" autoplay="autoplay"  preload="auto">
			<source src="https://cdn.microwebs.co/assets/videos/" type="video/mp4"/>
		</video>
		<div className="introsection-content aboutpowercontent">
				<h2>We believe in the<br/>power of ‘What If?’</h2>
				<p className="desktop">We combine digital strategy, UX and design to help<br/>clients think beyond ordinary.</p>
				<p className="mobile">We combine digital strategy, UX and design to help clients think beyond ordinary.</p>
		</div>
	</div>
</section>
<section id='aboutSectionWork' className="section">
	<div className="intro-video future-video">
		<div className="introsection-content aboutpowercontent">
				<h2>We work with<br/> big and small</h2>
				<p>Of all shapes and sizes, from all corners <br/>of the globe.</p>
		</div>
	</div>
</section>
<section id='aboutSectionCareer' className="section">
	<div className="intro-video book-video">
		<video className="video-intro video-book" autoplay="autoplay"  preload="auto">
			<source src="https://cdn.microwebs.co/assets/videos/" type="video/mp4"/>
		</video>
		<div className="introsection-content careercontent">
				<h2>We believe in <br/><span>passionate people</span></h2>
				<p>
					No matter their background or years <br/>of experience.
				</p>
				<a href="career.html" ><span className="intro-btn cr-btn">Careers</span></a>
		</div>
	</div>
</section>


<section id='aboutSectionAward' className="section">
	<div className="intro-video awd-row">
		<div className="awd-txt-col">
			<div className="introsection-content awrdcontent">
				<h2>We Stribe<br/><span>for better</span></h2>
				<p>
					We've Helped Companies win hearts and<br/>minds since 1999
				</p>
				<p className="awd-number"><span className="awrdnum">200</span><span className="plussign">+</span><span className="awdtxt">Industry Awards</span></p>
			</div>
		</div>
		<div className="awdpic-col">
			<div className="awd-pic">
				
			</div>
		</div>
	</div>
</section>

<section id='aboutSectionGlobal' className="section">
	<div className="intro-video global-video">
		<video className="video-intro video-global" autoplay="autoplay"  preload="auto">
			<source src="https://cdn.microwebs.co/assets/videos/" type="video/mp4"/>
		</video>
		<div className="introsection-content globalcontent">
				<h2>And think globally</h2>
				<p>
					Working with great companies around the<br/>world and across time-zones.
				</p>
		</div>
		<div className="global-table">
			<div className="global-table-content">
				<div className="table">
					<div className="table-top">
							SFO<i className="tblsign"></i>TYO
					</div>
					<div className="table-footer">
							8,095 mi
					</div>
				</div>
			</div>
			<div className="global-table-content">
				<div className="table">
					<div className="table-top">
							NYC<i className="tblsign"></i>PAR
					</div>
					<div className="table-footer">
							3,297 mi
					</div>
				</div>
			</div>
			<div className="global-table-content">
				<div className="table">
					<div className="table-top">
							SFO<i className="tblsign"></i>JED
					</div>
					<div className="table-footer">
							5,136 mi
					</div>
				</div>
			</div>
			<div className="global-table-content">
				<div className="table">
					<div className="table-top">
							NYC<i className="tblsign"></i>ZRH
					</div>
					<div class="table-footer">
							8,095 mi
					</div>
				</div>
			</div>
			<div className="global-table-content">
				<div className="table">
					<div className="table-top">
							NYC<i className="tblsign"></i>BRA
					</div>
					<div className="table-footer">
							8,095 mi
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section id='aboutSectionContact' className="section">
	<div className="intro-video contact-video">
		<div className="introsection-content contactcontent">
				<h2>Get In Touch</h2>
				<a href="#" ><span className="intro-btn cr-btn">Contact</span></a>
		</div>
	</div>
</section>
</div>
                            );
                        }}
                    />
            </React.Fragment>
        )
    }
}

export default About;
import React from 'react';
import './App.css';
import './assets/fonts/fonts.css';
import './index.css';
import Header from '../src/Components/Header';
import Home from '../src/Components/Pages/Home';
import About from '../src/Components/Pages/About';
import Career from '../src/Components/Pages/Career';
import Jobs from '../src/Components/Pages/Jobs';

import Works from '../src/Components/Pages/Works';

function App() {
  return (
    <div className="App">
     	<Header/>
      	<Home/>
    </div>
  );
}

export default App;

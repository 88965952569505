
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';


import React, { Component } from 'react';
import {Route,Link, BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import About from '../src/Components/Pages/About'
import Career from '../src/Components/Pages/Career';
import Jobs from '../src/Components/Pages/Jobs';
import Home from '../src/Components/Pages/Home';
import Works from '../src/Components/Pages/Works';


const myRouter = (
	<Router>
	<div className="sidebarnav-divsss">
					<a href="#"><img src="http://cdn.microwebs.co/assets/img/32.png" className="img-logo-nav"/></a>
		<ul className="navtop">
      <Link to="/">Home</Link>
			<Link to="/Works">PortFolio</Link>
			<Link to="/About">About</Link>
			<Link to="/Career">Career</Link>
			<Link to="/Jobs">Jobs</Link>

		</ul>
		<ul className="navmid">
			<Link to="/News">News</Link>
			<Link to="/Career">Career</Link>
			<Link to="/Jobs">Resources</Link>
		</ul>
		<Route exact path="/" component={App} />
		<Route path="/Works" component={Works} />
		<Route path="/About" component={About} />
		<Route path="/Career" component={Career} />
		<Route path="/Jobs" component={Jobs} />
	</div>

	</Router>

);
ReactDOM.render(
  myRouter,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {Component} from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

class Career extends Component{
    render(){
        return(
            <React.Fragment>
                
            <ReactFullpage
                    scrollOverflow={true}
                    
                    scrollbar={true}
                    
                    render={({ state, fullpageApi }) => {
                    return ( 
                        <div id="fullpage">
	<section id='careerSectionTop' className="section">
		<div className="intro-video">
			<div className="introsection-content careersection-content">
				<h2>Fantasy would<br/>love to hire your<br/><span className="color">awesomeness</span></h2>
			</div>
			<span className="mousebtn">
				<span className="mousebtn-wheel"></span>
			</span>
		</div>
	</section>
	<section className="section">
		<div className="job-row">
			<div className="col-md-6 parent">
				<a href="jobs.html" className="job-link">
					<h2>Lead UX Designer (Miami)</h2>
					<p>Miami</p>
				</a>
				<div className="col-content child"></div>
			</div>
			<div class="col-md-6 parent">
				<a href="jobs.html" className="job-link">
					<h2>Recruitment Manager</h2>
					<p>Remote</p>
				</a>
				<div className="col-content child" ></div>
			</div>
		</div>
	</section>
	<section className="section">
		<div className="job-row">
			<div className="col-md-6 parent">
				<a href="jobs.html" className="job-link">
					<h2>Excutive Producer</h2>
					<p>San Francisco</p>
				</a>
				<div class="col-content child" ></div>
			</div>
			<div className="col-md-6 parent">
				<a href="jobs.html" className="job-link">
					<h2>Art Director (SF)</h2>
					<p>San Francisco</p>
				</a>
				<div className="col-content child" ></div>
			</div>
		</div>
	</section>
	<section className="section">
		<div className="job-row">
			<div className="col-md-6 parent">
				<a href="jobs.html" className="job-link">
					<h2>Lead UX Designer (Miami)</h2>
					<p>Miami</p>
				</a>
				<div className="col-content child" ></div>
			</div>
			<div className="col-md-6 parent">
				<a href="jobs.html" className="job-link">
					<h2>Recruitment Manager(NY)</h2>
					<p>New York</p>
				</a>
				<div className="col-content child" ></div>
			</div>
		</div>
	</section>
	<section className="section">
		<div className="job-row">
			<div className="col-md-6 parent">
				<a href="jobs.html" className="job-link">
					<h2>Lead UX Designer (Miami)</h2>
					<p>Miami</p>
				</a>
				<div className="col-content child" ></div>
			</div>
			<div className="col-md-6 parent">
				<a href="jobs.html" className="job-link">
					<h2>Lead Visuall Designer(SF)</h2>
					<p>San Fanciscro</p>
				</a>
				<div className="col-content child" ></div>
			</div>
		</div>
	</section>
	<section className="section">
		<div className="job-row">
			<div className="col-md-6 parent">
				<a href="jobs.html" class="job-link">
					<h2>Sr. UX Designer (NY)</h2>
					<p>New York</p>
				</a>
				<div className="col-content child" ></div>
			</div>
			<div className="col-md-6 parent">
				
			</div>
		</div>
	</section>
</div>
                        
                        
                        );
                    }}
                />
        )
        
        </React.Fragment>
        )
    }
}

export default Career;
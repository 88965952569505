import React , {Component} from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

class Home extends Component{
    render(){
        return(
            <React.Fragment>
                <ReactFullpage
                    
                    
                    render={({ state, fullpageApi }) => {
                    return (
                        <section id="fullpage">

                            <section id='introsection' className="section">
                                <div className="intro-video">
                                    <video className="video-intro" autoPlay="autoplay"  preload="auto">
                                        <source src="https://cdn.microwebs.co/assets/videos/home.mp4" type="video/mp4"/>
                                    </video>
                                    <a href="work.html" className="intro_link">
                                        <div className="jobs-text-section introsection-div">
                                            <h2>Complete eCommerce <br/>Solution</h2>
                                            <span className="intro-btn">Explore Our Solution</span>
                                        </div>
                                    </a>
                                    <span className="mousebtn">
                                        <span className="mousebtn-wheel"></span>
                                    </span>
                                </div>
                            </section>
                            <section id='futuresection' className="section">
                                <div className="intro-video future-video">
                                    <video className="video-intro video-future" autoPlay="autoplay"  preload="auto">
                                        <source src="https://cdn.microwebs.co/assets/videos/739910639.mp4" type="video/mp4"/>
                                    </video>
                                    <a href="extra.html" className="intro_link">
                                        <div className="jobs-text-section introsection-div">
                                            <h2>Love for Brands <br/><span>looking to grow Online</span></h2>
                                            <span className="intro-btn">Explore Our Company</span>
                                        </div>
                                    </a>
                                </div>
                            </section>
                            
                            <section className="section">
                                <div className="job-row">
                                    <div className="col-md-6 parent">
                                        <a href="jobs.html" className="job-link mide_text">
                                            <h2>Love for Brands <br/><span>We’re a world leading product innovation team</span></h2>
                                            <p>
                                            Collaborating with ambitious companies to create digital experiences used by billions everyday.
                                            </p>
                                            <span className="intro-btn">Explore Our Company</span>
                                        </a>
                                        <div className="col-content child"  style={{backgroundColor: "#fff"}}></div>
                                    </div>
                                    <div class="col-md-6 parent" style={{backgroundColor: "#fd0202"}}>
                                        
                                        <div className="logo_mid">
                                            <img src="http://cdn.microwebs.co/assets/img/152.png" className="img_lgo_mid"/>
                                        </div>
                                        {/* <div className="col-content child" style={{backgroundColor: "#fd0202"}}></div> */}
                                    </div>
                                </div>
                            </section>
                            <section className="section">
                                <div className="job-row">
                                    <div className="col-md-6 parent">
                                        <a href="jobs.html" className="job-link mide_text">
                                            <h2>Love for Brands <br/><span>We’re a world leading product innovation team</span></h2>
                                            <p>
                                            Collaborating with ambitious companies to create digital experiences used by billions everyday.
                                            </p>
                                        </a>
                                        <div className="col-content child"  style={{backgroundColor: "#fff"}}></div>
                                    </div>
                                    {/* style={{backgroundImage: "url(https://cdn.microwebs.co/assets/img/maintilemeganmarc_image1.jpg)"}} */}
                                    <div class="col-md-6 parent video_middle" >
                                        <video className="video-intro video-future video_middle_f" autoPlay="autoplay"  preload="auto">
                                            <source src="https://d2kdkox9jtuwgl.cloudfront.net/pages/NewAboutPage/videos/about5Desktop.mp4" type="video/mp4"/>
                                        </video>
                                       
                                        {/* <div className="col-content child" style={{backgroundColor: "#fd0202"}}></div> */}
                                    </div>
                                </div>
                            </section>
                            <section className="section">
                                <div className="job-row">
                                    <div className="col-md-6 parent">
                                        <a href="jobs.html" className="job-link mide_text">
                                            <h2>Welcome To<br/><span>Microwebs</span></h2>
                                            <p>
                                                Excelent In Services
                                            </p>
                                        </a>
                                        <div className="col-content child"  style={{backgroundColor: "#ddd"}}></div>
                                    </div>
                                    <div class="col-md-6 parent" style={{backgroundColor: "#fd0202"}}>
                                        
                                        <div className="logo_mid">
                                            <img src="http://cdn.microwebs.co/assets/img/152.png" className="img_lgo_mid"/>
                                        </div>
                                        {/* <div className="col-content child" style={{backgroundColor: "#fd0202"}}></div> */}
                                    </div>
                                </div>
                            </section>
                            <section className="section">
                                <div className="job-row">
                                    <div className="col-md-6 parent">
                                        <a href="jobs.html" className="job-link mide_text">
                                            <h2>Follow us on<br/><span>Facebook</span></h2>
                                            <p>
                                                Exlpore our Facebook Comunity
                                            </p>
                                            <span className="intro-btn">Facebook</span>
                                        </a>
                                        <div className="col-content child"  style={{backgroundColor: "#fff"}}></div>
                                    </div>
                                    <div class="col-md-6 parent" style={{backgroundImage: "url(https://cdn.microwebs.co/assets/img/maintilemeganmarc_image1.jpg)"}}>
                                        
                                        <div className="logo_mid">
                                            <img src="http://cdn.microwebs.co/assets/img/152.png" className="img_lgo_mid"/>
                                        </div>
                                        {/* <div className="col-content child" style={{backgroundColor: "#fd0202"}}></div> */}
                                    </div>
                                </div>
                            </section>
                            <section id='middlesection' className="section">
                                <div className="intro-video middle-video">
                                    <video className="video-intro video-middle" autoPlay="autoplay"  preload="auto">
                                        <source src="https://cdn.microwebs.co/assets/videos/739910115.mp4" type="video/mp4"/>
                                    </video>
                                    <a href="work.html" className="intro_link">
                                        <div className="jobs-text-section introsection-div">
                                            <h2>What if shopping<br/><span>were smarter?</span></h2>
                                            <span className="intro-btn">Explore Our Work</span>
                                        </div>
                                    </a>
                                </div>
                            </section>
                            <section id='blogSection' className="section">
                                <div className="blog_container">
                                    <div className="text-center">
                                        <h4>From Our Blog</h4>
                                        <p>
                                        Collaborating with ambitious companies to create digital experiences used by billions everyday.
                                        </p>
                                        <a href="#">Read Blog</a>
                                    </div>
                                    <div className="row blog_row">
                                        <div className="col-md-4">
                                            <div className="blog_content">
                                                <a href="#">
                                                <div className="blog_img">
                                                    <img src="https://images.prismic.io/fantasy-site/137befe7-3d2e-44de-b547-ff837dcdd078_Elon+Musk.jpg" className="img-responsive"></img>
                                                </div>
                                                <div className="blog_title">
                                                    <h4>
                                                        We have latest tools
                                                    </h4>
                                                    <h6>By Microwebs</h6>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="blog_content">
                                                <a href="#">
                                                <div className="blog_img">
                                                    <img src="https://images.prismic.io/fantasy-site/178a1adb-3aca-43c9-bb91-51e31cca6277_Determined.jpg" className="img-responsive"></img>
                                                </div>
                                                <div className="blog_title">
                                                    <h4>
                                                        We have latest tools
                                                    </h4>
                                                    <h6>By Microwebs</h6>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="blog_content">
                                                <a href="#">
                                                <div className="blog_img">
                                                    <img src="https://images.prismic.io/fantasy-site/137befe7-3d2e-44de-b547-ff837dcdd078_Elon+Musk.jpg" className="img-responsive"></img>
                                                </div>
                                                <div className="blog_title">
                                                    <h4>
                                                        We have latest tools
                                                    </h4>
                                                    <h6>By Microwebs</h6>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section id='book-section' className="section">
                                <div className="intro-video book-video">
                                    <video className="video-intro video-book" autoPlay="autoplay"  preload="auto">
                                        <source src="https://cdn.microwebs.co/assets/videos/739910140.mp4" type="video/mp4"/>
                                    </video>
                                    <a href="career.html" className="intro_link">
                                        <div className="jobs-text-section introsection-div">
                                            <h2>Love to hire<br/><span>your awesomeness</span></h2>
                                            <span className="intro-btn">Career With Microwebs</span>
                                        </div>
                                    </a>
                                </div>
                            </section>


                            <section id='footersection' className="section">
                                <div className="intro-video">
                                    <a href="work.html" className="intro_link">
                                        <div className="jobs-text-section introsection-div">
                                            <h2>Love getting services<br/><span> from industry leaders</span></h2>
                                            <span className="intro-btn">Partners</span>
                                        </div>
                                    </a>
                                </div>
                            </section>
                            <section className="section">
                                <div className="job-row">
                                    <div className="col-md-6 parent">
                                        <a href="jobs.html" className="job-link mide_text">
                                            <h2>Contact With Us</h2>
                                        </a>
                                        <div className="col-content child"  style={{backgroundColor: "#fff"}}></div>
                                    </div>
                                    <div class="col-md-6 parent" style={{backgroundColor: "#000"}}>
                                        
                                        <div className="logo_mid">
                                            <img src="http://cdn.microwebs.co/assets/img/152.png" className="img_lgo_mid"/>
                                        </div>
                                        {/* <div className="col-content child" style={{backgroundColor: "#fd0202"}}></div> */}
                                    </div>
                                </div>
                            </section>
                            </section>
                            );
                        }}
                    />
            </React.Fragment>
        )
    }
}

export default Home;
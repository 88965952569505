import React , { Component } from 'react';
import ReactFullpage, { f } from '@fullpage/react-fullpage';

class Works extends Component{
    render(){
        return(
            <React.Fragment>
                <ReactFullpage
                    scrollOverflow={true}
                    
                    scrollbar={true}
                    
                    render={({ state, fullpageApi }) => {
                    return (
                        <div id="fullpage">

	<section id='WorkSectionTop' className="section">
		<div className="intro-video">
			<div className="introsection-content worksection-content">
				<h2>Fantasy would<br/>love to hire your<br/><span class="color">awesomeness</span></h2>
			</div>
		</div>
		<div className="stickyheader">
			<div className="work-nav" id="menuitem">
				<div className="nav-items">
					<div className="nav-items-content">
						<a href="#job1"><img src="https://cdn.microwebs.co/assets/img/335873.svg"/>
						<p>What If?</p>
						</a>
					</div>
				</div>
				<div className="nav-items">
					<div className="nav-items-content">
						<a href="#job2"><img src="https://cdn.microwebs.co/assets/img/114371.svg"/>
						<p>Product UI</p>
						</a>
					</div>
				</div>
				<div className="nav-items">
					<div className="nav-items-content">
						<a href="#job3"><img src="https://cdn.microwebs.co/assets/img/61620.svg"/>
						<p>Web</p>
						</a>
					</div>
				</div>
				<div className="nav-items">
					<div className="nav-items-content">
						<a href="#job4"><img src="https://cdn.microwebs.co/assets/img/61779.svg"/>
						<p>Apps</p>
						</a>
					</div>
				</div>
				<div className="nav-items">
					<div className="nav-items-content">
						<a href="#"><img src="https://cdn.microwebs.co/assets/img/160150.svg"/>
						<p>Dribble</p>
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	
	<section className="section sectioncustom" id="job1">
		<div className="work-row">
			<div className="workhf">
				<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg3">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/bl.jpg')"}}></div>
			</div>
			<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg4">
					<img src="https://cdn.microwebs.co/assets/img/logo_mobile.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverair.jpg')"}}></div>
			</div>
			</div>
			<div className="workhf">
				<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg5">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div class="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/covertwo.jpg')"}}></div>
			</div>
			<div class="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg1">
					<img src="https://cdn.microwebs.co/assets/img/logo_mobile.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverone.jpg')"}}></div>
			</div>
			</div>
		</div>
	</section>
	<section className="section" id="job2">
		<div className="work-row">
			<div className="workhf" >
				<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg2">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverfive.jpg')"}}></div>
			</div>
			<div class="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg1">
					<img src="https://cdn.microwebs.co/assets/img/logo_mobile.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverfour.jpg')"}}></div>
			</div>
			</div>
			<div className="workhf" >
				<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg4">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/covertwo.jpg')"}}></div>
			</div>
			<div class="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg5">
					<img src="https://cdn.microwebs.co/assets/img/logo_mobile.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/cover.jpg')"}}></div>
			</div>
			</div>
		</div>
	</section>
	<section className="section" id="job3">
		<div className="work-row">
			<div className="workhful">
				<div className="col-md-6 parent work-fl">
				<a href="projects -rtl.html" className="job-link work-link bg6">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/bl.jpg')"}}></div>
			</div>
			</div>
			<div className="workhf">
				<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg7">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverfive.jpg')"}}></div>
			</div>
			<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg1">
					<img src="https://cdn.microwebs.co/assets/img/logo_mobile.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverair.jpg')"}}></div>
			</div>
			</div>
		</div>
	</section>
		<section className="section">
		<div className="work-row">
			<div className="workhf">
				<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg2">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverfive.jpg')"}}></div>
			</div>
			<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg3">
					<img src="https://cdn.microwebs.co/assets/img/logo_mobile.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverone.jpg')"}}></div>
			</div>
			</div>
			<div className="workhf">
				<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg4">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverair.jpg')"}}></div>
			</div>
			<div className="col-md-6 parent work-hf">
				<a href="projects -rtl.html" className="job-link work-link bg5">
					<img src="https://cdn.microwebs.co/assets/img/logo_mobile.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/cover.jpg')"}}></div>
			</div>
			</div>
		</div>
	</section>
	<section className="section" id="job4">
		<div className="work-row">
			<div className="workhful" style={{float:'left'},{width:'50%'}}>
				<div className="col-md-6 parent work-fl">
				<a href="projects -rtl.html" className="job-link work-link bg6">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/coverthre.jpg')"}}></div>
			</div>
			</div>
			<div className="workhful"  style={{float:'left'},{width:'50%'}}>
				<div className="col-md-6 parent work-fl">
				<a href="projects -rtl.html" className="job-link work-link bg7">
					<img src="https://cdn.microwebs.co/assets/img/logo-tinder.png" className="img-work"/>
				</a>
				<div className="col-content child" style={{backgroundImage:"url('https://cdn.microwebs.co/assets/img/covertwo.jpg')"}}></div>
			</div>
			</div>
		</div>
	</section>
</div>
                        );
                        }}
                    />
            </React.Fragment>
        )
    }
}

export default Works;